// Process links like https://example.org/

'use strict';

// RFC3986: scheme = ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
var SCHEME_RE = /(?:^|[^a-z0-9.+-])([a-z][a-z0-9.+-]*)$/i;
module.exports = function linkify(state, silent) {
  var pos, max, match, proto, link, url, fullUrl, token;
  if (!state.md.options.linkify) return false;
  if (state.linkLevel > 0) return false;
  pos = state.pos;
  max = state.posMax;
  if (pos + 3 > max) return false;
  if (state.src.charCodeAt(pos) !== 0x3A /* : */) return false;
  if (state.src.charCodeAt(pos + 1) !== 0x2F /* / */) return false;
  if (state.src.charCodeAt(pos + 2) !== 0x2F /* / */) return false;
  match = state.pending.match(SCHEME_RE);
  if (!match) return false;
  proto = match[1];
  link = state.md.linkify.matchAtStart(state.src.slice(pos - proto.length));
  if (!link) return false;
  url = link.url;

  // invalid link, but still detected by linkify somehow;
  // need to check to prevent infinite loop below
  if (url.length <= proto.length) return false;

  // disallow '*' at the end of the link (conflicts with emphasis)
  url = url.replace(/\*+$/, '');
  fullUrl = state.md.normalizeLink(url);
  if (!state.md.validateLink(fullUrl)) return false;
  if (!silent) {
    state.pending = state.pending.slice(0, -proto.length);
    token = state.push('link_open', 'a', 1);
    token.attrs = [['href', fullUrl]];
    token.markup = 'linkify';
    token.info = 'auto';
    token = state.push('text', '', 0);
    token.content = state.md.normalizeLinkText(url);
    token = state.push('link_close', 'a', -1);
    token.markup = 'linkify';
    token.info = 'auto';
  }
  state.pos += url.length - proto.length;
  return true;
};