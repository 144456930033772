// Process escaped chars and hardbreaks

'use strict';

var isSpace = require('../common/utils').isSpace;
var ESCAPED = [];
for (var i = 0; i < 256; i++) {
  ESCAPED.push(0);
}
'\\!"#$%&\'()*+,./:;<=>?@[]^_`{|}~-'.split('').forEach(function (ch) {
  ESCAPED[ch.charCodeAt(0)] = 1;
});
module.exports = function escape(state, silent) {
  var ch1,
    ch2,
    origStr,
    escapedStr,
    token,
    pos = state.pos,
    max = state.posMax;
  if (state.src.charCodeAt(pos) !== 0x5C /* \ */) return false;
  pos++;

  // '\' at the end of the inline block
  if (pos >= max) return false;
  ch1 = state.src.charCodeAt(pos);
  if (ch1 === 0x0A) {
    if (!silent) {
      state.push('hardbreak', 'br', 0);
    }
    pos++;
    // skip leading whitespaces from next line
    while (pos < max) {
      ch1 = state.src.charCodeAt(pos);
      if (!isSpace(ch1)) break;
      pos++;
    }
    state.pos = pos;
    return true;
  }
  escapedStr = state.src[pos];
  if (ch1 >= 0xD800 && ch1 <= 0xDBFF && pos + 1 < max) {
    ch2 = state.src.charCodeAt(pos + 1);
    if (ch2 >= 0xDC00 && ch2 <= 0xDFFF) {
      escapedStr += state.src[pos + 1];
      pos++;
    }
  }
  origStr = '\\' + escapedStr;
  if (!silent) {
    token = state.push('text_special', '', 0);
    if (ch1 < 256 && ESCAPED[ch1] !== 0) {
      token.content = escapedStr;
    } else {
      token.content = origStr;
    }
    token.markup = origStr;
    token.info = 'escape';
  }
  state.pos = pos + 1;
  return true;
};